import { Button, ButtonProps } from '@/components-shad/ui/button';
import { RotateCwIcon } from 'lucide-react';
import { cn } from '@/shared/helpers';
import { useVisiblePendingState } from '@/shared/hooks/useVisiblePendingState';

type Props = Omit<ButtonProps, 'isPending' | 'onPress' | 'showPendingSpinner' | 'icon'> & {
  onPress: () => Promise<void>;
};

export function RefreshButton({ onPress, className, isDisabled, ...props }: Props) {
  const [onPressWithPending, isPending] = useVisiblePendingState(onPress);
  return (
    <Button
      aria-label="Refresh"
      className={cn('group', className)}
      isPending={isPending}
      isDisabled={isPending || isDisabled}
      showPendingSpinner={false}
      onPress={onPressWithPending}
      icon={
        <div className="group-data-[pending]:animate-spin group-data-[pending]:delay-300">
          <RotateCwIcon className="transition-transform group-data-[pending]:scale-90" />
        </div>
      }
      {...props}
    />
  );
}
