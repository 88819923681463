import { CarrierServiceDTO } from '@invenco/common-interface/shipping';
import { Page, PageContent } from '@/components-shad/layout';
import { Header } from '@/components-shad/header/Header';
import { Link } from '@/components-shad/ui/link';
import { formatDateTime } from '@/shared/helpers';
import { DataTable } from '@/components-shad/data-table/DataTable';
import { DataTableSearchPanel } from '@/components-shad/data-table';
import { useCarrierListPage } from './useCarrierListPage';

export function CarrierListShad() {
  const {
    models: { isLoading, hasError, rows, pagination, total, query },
    operations: { search, navigate, refresh, onClickRow },
  } = useCarrierListPage();

  return (
    <Page>
      <Header
        title="Carriers"
        actions={
          <Link variant="default" href="/shipping/carriers/new">
            Create carrier
          </Link>
        }
      />
      <PageContent variant="column">
        <DataTableSearchPanel
          loading={isLoading}
          searchPlaceholderText="Search..."
          defaultSearchValue={query}
          pagination={pagination}
          total={total}
          onSearch={search}
          onNavigate={navigate}
          onReload={refresh}
        />

        <DataTable
          rows={rows}
          columns={[
            { title: 'Name', key: 'name' },
            { title: 'Code', key: 'code' },
            {
              title: 'Services',
              key: 'carrierServices',
              render: (services?: CarrierServiceDTO[]) =>
                services
                  ?.map(({ code }) => code)
                  .sort()
                  .join(', '),
            },
            {
              title: 'Created',
              key: 'createdAt',
              render: (createdAt: string) => formatDateTime(createdAt),
            },
          ]}
          loading={isLoading}
          hasError={hasError}
          onClickRow={onClickRow}
        />
      </PageContent>
    </Page>
  );
}
