import { EllipsisVerticalIcon } from 'lucide-react';
import { DetailMain, DetailSide, Page, PageContent } from '@/components-shad/layout';
import { Header } from '@/components-shad/header/Header';
import { formatDate } from '@/shared/helpers';
import { Button } from '@/components-shad/ui/button';
import { Card } from '@/components-shad/ui/card';
import { DataTable, useRowNavigation } from '@/components-shad/data-table';
import { Menu, MenuItem, MenuPopover, MenuTrigger } from '@/components-shad/ui/menu';
import { ServiceAgreementAction, UserAction } from '@invenco/common-interface/accounts';
import { DialogOverlay, DialogTrigger } from '@/components-shad/ui/dialog';
import { AddUserDialog } from '@/pages/platform/accounts/account-details/shad/dialogs/AddUserDialog';
import { DetailList } from '@/components-shad/detail-list/DetailList';
import { Separator } from '@/components-shad/ui/separator';
import { locationStatusType, serviceAgreementStatusType } from '@/shared/status-maps';
import { locationStatusTitle, serviceAgreementStatusTitle } from '@/shared/title-maps';
import { AddTenantDialog } from '@/pages/platform/accounts/account-details/shad/dialogs/AddTenantDialog';
import { AccountDetailDialog } from '@/pages/platform/accounts/account-details/shad/dialogs/AccountDetailDialog';
import { StatusBadge } from '@/components-shad/StatusBadge';
import { useAccountDetailsPage } from '../useAccountDetailsPage';
import { ServiceAgreementDialog } from './dialogs/ServiceAgreementDialog';

export function AccountDetailsShad() {
  const {
    models: {
      isNew,
      isServiceAgreementModalOpen,
      isCreateAccountMutationPending,
      isUpdateAccountMutationPending,
      isLoading,
      canSave,
      canUpdate,
      account,
      isTerminate,
      selectedServiceAgreement,
    },
    operations: {
      openServiceAgreementModal,
      closeServiceAgreementModal,
      addServiceAgreement,
      refresh,
      updateAccount,
      createAccountMutation,
      updateAccountMutation,
      addUser,
      deleteMembership,
      resendInvite,
      addToWarehouse,
      editServiceAgreement,
      terminate,
      terminateServiceAgreement,
      updateServiceAgreement,
    },
  } = useAccountDetailsPage();
  const { onClickRow: onClickLocationRow } = useRowNavigation({ baseUrl: '/locations' });

  return (
    <Page>
      <Header
        title={isNew ? 'New account' : account.name}
        breadcrumbs={[{ url: '/accounts', title: 'Accounts' }]}
        loading={isLoading}
        onRefresh={!isNew ? refresh : undefined}
        hasBackButton
        actions={
          // TODO: save functionality should be consolidated inside the hook
          <Button
            isDisabled={isNew ? !canSave : !canUpdate}
            isPending={isNew ? isCreateAccountMutationPending : isUpdateAccountMutationPending}
            onPress={() => (isNew ? void createAccountMutation() : void updateAccountMutation())}
          >
            Save
          </Button>
        }
      />

      <PageContent variant="split">
        <DetailMain>
          <Card
            title="Users"
            count={account.memberships?.length ?? 0}
            actions={
              !isNew && (
                <DialogTrigger>
                  <Button variant="outline" size="sm">
                    Invite user
                  </Button>
                  <DialogOverlay>
                    <AddUserDialog onSubmit={(userEmail) => addUser({ userEmail })} />
                  </DialogOverlay>
                </DialogTrigger>
              )
            }
          >
            <DataTable
              simple
              loading={isLoading}
              rows={account.memberships}
              columns={[
                { key: 'name', dataIndex: 'user', title: 'Name', render: ({ name }) => name },
                { key: 'email', dataIndex: 'user', title: 'Email', render: ({ email }) => email },
                {
                  key: 'id',
                  title: '',
                  align: 'right',
                  render: (membershipId: string, { user }) =>
                    !isNew && (
                      <MenuTrigger>
                        <Button
                          variant="ghost"
                          icon={<EllipsisVerticalIcon />}
                          aria-label="Further actions"
                        />

                        <MenuPopover>
                          <Menu>
                            {user?.allowableActions.includes(UserAction.RESEND_INVITE) && (
                              <MenuItem id="resend" onAction={() => void resendInvite(user.email)}>
                                Resend invite
                              </MenuItem>
                            )}
                            <MenuItem
                              id="delete"
                              onAction={() => void deleteMembership(membershipId)}
                            >
                              Delete
                            </MenuItem>
                          </Menu>
                        </MenuPopover>
                      </MenuTrigger>
                    ),
                },
              ]}
            />
          </Card>

          <Card
            title="Service agreements"
            count={account.serviceAgreements?.length ?? 0}
            actions={
              !isNew && (
                <Button variant="outline" size="sm" onPress={openServiceAgreementModal}>
                  Add service agreement
                </Button>
              )
            }
          >
            <DataTable
              simple
              loading={isLoading}
              rows={account.serviceAgreements}
              columns={[
                { key: 'name', title: 'Name' },
                {
                  key: 'commencementDate',
                  title: 'Commencement Date',
                  render: (date) => (date ? formatDate(date) : ''),
                },
                { key: 'locationName', title: 'Location' },
                {
                  key: 'status',
                  title: 'Status',
                  render: (status) => (
                    <StatusBadge
                      status={status}
                      type={serviceAgreementStatusType}
                      title={serviceAgreementStatusTitle}
                    />
                  ),
                },
                {
                  key: 'id',
                  title: '',
                  align: 'right',
                  render: (id, { allowableActions }) =>
                    allowableActions.length > 0 && (
                      <MenuTrigger>
                        <Button
                          variant="ghost"
                          icon={<EllipsisVerticalIcon />}
                          aria-label="Further actions"
                        />

                        <MenuPopover>
                          <Menu>
                            {allowableActions.includes(ServiceAgreementAction.EDIT) && (
                              <MenuItem id="resend" onAction={() => editServiceAgreement(id)}>
                                Edit
                              </MenuItem>
                            )}
                            {allowableActions.includes(ServiceAgreementAction.TERMINATE) && (
                              <MenuItem id="delete" onAction={() => terminate(id)}>
                                Terminate
                              </MenuItem>
                            )}
                          </Menu>
                        </MenuPopover>
                      </MenuTrigger>
                    ),
                },
              ]}
            />
          </Card>

          <Card
            title="Locations"
            count={account.locations?.length ?? 0}
            actions={
              !isNew && (
                <DialogTrigger>
                  <Button variant="outline" size="sm">
                    Invite to warehouse
                  </Button>
                  <DialogOverlay>
                    <AddTenantDialog accountId={account.id!} addToWarehouse={addToWarehouse} />
                  </DialogOverlay>
                </DialogTrigger>
              )
            }
          >
            <DataTable
              simple
              columns={[
                { key: 'name', title: 'Name' },
                { key: 'type', title: 'Type' },
                { key: 'warehouseLocationName', title: 'Warehouse Location' },
                {
                  title: 'Status',
                  key: 'status',
                  render: (status) => (
                    <StatusBadge
                      status={status}
                      type={locationStatusType}
                      title={locationStatusTitle}
                    />
                  ),
                },
              ]}
              rows={account.locations}
              loading={isLoading}
              onClickRow={onClickLocationRow}
            />
          </Card>
        </DetailMain>

        <DetailSide>
          <Card
            title="Details"
            actions={
              <DialogTrigger defaultOpen={isNew}>
                <Button variant="outline" size="sm">
                  Edit
                </Button>
                <DialogOverlay>
                  <AccountDetailDialog isNew={isNew} account={account} onSubmit={updateAccount} />
                </DialogOverlay>
              </DialogTrigger>
            }
          >
            <DetailList
              isLoading={isLoading}
              items={[
                { label: 'Code', value: account.code },
                { label: 'Billing ID', value: account.externalId },
              ]}
            />
            <Separator />
            <DetailList
              title="Primary contact"
              isLoading={isLoading}
              items={[
                { label: 'Name', value: account.primaryName },
                { label: 'Email', value: account.primaryEmail },
                { label: 'Phone', value: account.primaryPhone },
              ]}
            />
          </Card>
        </DetailSide>
      </PageContent>

      <DialogTrigger
        isOpen={isServiceAgreementModalOpen}
        onOpenChange={(value) => {
          if (!value) {
            closeServiceAgreementModal();
          }
        }}
      >
        <DialogOverlay>
          <ServiceAgreementDialog
            isTerminating={isTerminate}
            serviceAgreement={selectedServiceAgreement}
            locations={account.locations}
            addServiceAgreement={addServiceAgreement}
            updateServiceAgreement={updateServiceAgreement}
            terminateServiceAgreement={terminateServiceAgreement}
          />
        </DialogOverlay>
      </DialogTrigger>
    </Page>
  );
}
