import { Page, PageContent } from '@/components-shad/layout';
import { Header } from '@/components-shad/header/Header';
import { DataTable } from '@/components-shad/data-table/DataTable';
import { formatDateTime } from '@/shared/helpers';
import { DataTableSearchPanel, SortOption } from '@/components-shad/data-table';
import { Link } from '@/components-shad/ui/link';
import { useAccountListPage } from './useAccountListPage';

const sortOptions: SortOption[] = [
  { key: 'createdAt', title: 'Created' },
  { key: 'name', title: 'Name' },
];

export function AccountListShad() {
  const {
    models: { isLoading, hasError, rows, pagination, total, query, sort },
    operations: { search, navigate, changeSort, refresh, onClickRow },
  } = useAccountListPage();

  return (
    <Page>
      <Header
        title="Accounts"
        actions={
          <Link variant="default" href="/accounts/new">
            Create account
          </Link>
        }
      />
      <PageContent variant="column">
        <DataTableSearchPanel
          loading={isLoading}
          sortOptions={sortOptions}
          total={total}
          sort={sort}
          defaultSearchValue={query}
          pagination={pagination}
          onSearch={search}
          onNavigate={navigate}
          onReload={refresh}
          onChangeSort={changeSort}
        />
        <DataTable
          aria-label="Accounts"
          rows={rows}
          columns={[
            { title: 'Name', key: 'name' },
            { title: 'Primary Contact', key: 'primaryName' },
            { title: 'Primary Email', key: 'primaryEmail' },
            { title: 'Primary Phone', key: 'primaryPhone' },
            { title: 'Created', key: 'createdAt', render: (val) => formatDateTime(val) },
          ]}
          loading={isLoading}
          hasError={hasError}
          sort={sort}
          sortOptions={sortOptions}
          onClickRow={onClickRow}
          onChangeSort={changeSort}
        />
      </PageContent>
    </Page>
  );
}
