import { useFeatureFlags } from '@/shared/providers/FeatureFlagProvider';
import { useLocation } from 'react-router';
import { AccessContext } from '@invenco/common-interface/auth';
import { useAuthToken } from './useAuthToken';

const INCLUDED_BASE_PATHS = [
  '/dashboard', // to prevent redirect flicker
  '/accounts',
  '/locations',
  '/shipping/carriers',
  '/shipping/carrier_services',
  '/shipping/prices/',
  '/dev-config',
  '/features',
];

export function useNewUiFeatureFlag() {
  const { getFeatureFlag } = useFeatureFlags();
  const { accessContext } = useAuthToken();
  const location = useLocation();

  const isFlagEnabled = getFeatureFlag('new-ui');
  // we also include the root path to prevent redirection flicker
  const isIncludedPath =
    location.pathname === '/' ||
    INCLUDED_BASE_PATHS.some((path) => location.pathname.startsWith(path));

  // We can't determine this if auth0 is still loading the context.
  // Doing it this way means no flicker a new UI context upon refresh, but a flicker an old UI context.
  // Also: isLoading is not reliable for accessContext, so we just check if it's undefined.
  const isExcludedContext = accessContext !== undefined && accessContext !== AccessContext.PLATFORM;

  return isFlagEnabled && isIncludedPath && !isExcludedContext;
}
