import { useMemo } from 'react';
import debounce from 'lodash/debounce';
import { SearchPanelProps as BaseProps } from '@/components/data-table/search-panel/SearchPanel';
import { useLoadedValue } from '@/shared/hooks/useLoadedValue';
import { SEARCH_DEBOUNCE_TIMEOUT_MS } from '@/constants';
import { SearchField } from '@/components-shad/ui/searchfield';
import { Separator } from '@/components-shad/ui/separator';
import { Menu, MenuItem, MenuPopover, MenuTrigger } from '@/components-shad/ui/menu';
import { Button } from '@/components-shad/ui/button';
import {
  ArrowDownAZIcon,
  ArrowDownZAIcon,
  ArrowUpDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from 'lucide-react';
import { Toolbar } from '@/components-shad/ui/toolbar';
import { Group } from 'react-aria-components';
import { Page } from '@/shared/types';
import { cn, formatNumber } from '@/shared/helpers';
import { RefreshButton } from '@/components-shad/RefreshButton';

export type SearchPanelProps = Omit<
  BaseProps<''>,
  'filters' | 'filterSelections' | 'onChangeFilterSelections' | 'onReload'
> & {
  onReload: () => Promise<void>;
};

export function DataTableSearchPanel({
  loading,
  defaultSearchValue,
  searchPlaceholderText = 'Search...',
  total: currentTotal,
  pagination,
  onReload,
  onSearch,
  onNavigate,

  sortOptions,
  sort,
  onChangeSort,
}: SearchPanelProps) {
  const total = useLoadedValue(currentTotal, loading);

  /*
    TODO: the debouncing should be moved out of the table and into the page. The ideal solution probably involves
    using a solution similar to what useAutoCompleteQuery does to provide immediate cached response while still
    debouncing network requests.
   */
  const handleSearch = useMemo(() => debounce(onSearch, SEARCH_DEBOUNCE_TIMEOUT_MS), [onSearch]);

  const handleNavigate = (page: Page | null | undefined) => {
    if (page) {
      onNavigate(page);
    }
  };

  const onSelectSort = (key: string) => {
    const newDirection = sort?.key === key && sort.direction === 'asc' ? 'desc' : 'asc';
    onChangeSort?.({ key, direction: newDirection });
  };

  return (
    <Toolbar className="flex-col-reverse gap-x-4 gap-y-2 sm:flex-row sm:items-center">
      <Group className="flex flex-1 gap-2">
        <SearchField
          aria-label={searchPlaceholderText}
          placeholder={searchPlaceholderText}
          defaultValue={defaultSearchValue}
          onChange={handleSearch}
          autoFocus
          className="flex-1"
        />

        {sortOptions && (
          <MenuTrigger>
            <Button variant="outline" icon={<ArrowUpDownIcon />} aria-label="Sort by" />
            <MenuPopover>
              <Menu items={sortOptions} onAction={(key) => onSelectSort(key as string)}>
                {(item) => (
                  <MenuItem id={item.key}>
                    {item.title}
                    {item.key === sort?.key &&
                      (sort?.direction === 'desc' ? <ArrowDownZAIcon /> : <ArrowDownAZIcon />)}
                  </MenuItem>
                )}
              </Menu>
            </MenuPopover>
          </MenuTrigger>
        )}
      </Group>
      <Separator orientation="vertical" className="hidden sm:block" />
      <Group className="flex justify-between gap-2 sm:justify-normal">
        <div className="flex gap-2">
          <Button
            variant="outline"
            icon={<ChevronLeftIcon />}
            isDisabled={!pagination?.prev}
            onPress={() => handleNavigate(pagination?.prev)}
            aria-label="Previous page"
          />
          <Button
            variant="outline"
            icon={<ChevronRightIcon />}
            isDisabled={!pagination?.next}
            onPress={() => handleNavigate(pagination?.next)}
            aria-label="Next page"
          />
        </div>

        <div className="flex gap-2">
          <span
            className={cn(
              'inline-flex items-center px-2 text-xs font-medium transition-[opacity] duration-200',
              loading && 'opacity-50',
            )}
          >
            {formatNumber(total || 0, 0)} total
          </span>
          <RefreshButton variant="outline" aria-label="Reload table" onPress={onReload} />
        </div>
      </Group>
    </Toolbar>
  );
}
