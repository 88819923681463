import { Page, PageContent } from '@/components-shad/layout';
import { Header } from '@/components-shad/header/Header';
import { Link } from '@/components-shad/ui/link';
import { DataTable, DataTableSearchPanel } from '@/components-shad/data-table';
import { formatDate, formatMoney, truncateNumber } from '@/shared/helpers';
import { ShippingPriceBandDTO } from '@invenco/common-interface/shipping';
import { sortBands } from '@/pages/platform/shipping-prices/sortBands';
import { Skeleton } from '@/components-shad/ui/skeleton';
import { useShippingPriceListPage } from './useShippingPriceListPage';

function getShortPriceBandList(priceBands: ShippingPriceBandDTO[]) {
  return (
    sortBands(priceBands)
      .slice(0, 4)
      .map(({ markup }, index) => {
        const startingAt = index > 0 ? priceBands[index - 1].maxCost : undefined;
        return `${truncateNumber(markup * 100, 1)}%${
          startingAt === 0 || startingAt ? ` at ${formatMoney(startingAt)}` : ''
        }`;
      })
      .join(', ') + (priceBands.length > 4 ? ', ...' : '')
  );
}

export function ShippingPriceListShad() {
  const {
    models: {
      isLoading,
      isLoadingAccounts,
      hasError,
      rows,
      pagination,
      total,
      query,
      accountsById,
    },
    operations: { search, navigate, refresh, onClickRow },
  } = useShippingPriceListPage();

  return (
    <Page>
      <Header
        title="Shipping prices"
        actions={<Link href="/shipping/prices/new">Create shipping price</Link>}
      />

      <PageContent variant="column">
        <DataTableSearchPanel
          loading={isLoading}
          defaultSearchValue={query}
          pagination={pagination}
          total={total}
          onSearch={search}
          onNavigate={navigate}
          onReload={refresh}
        />

        <DataTable
          rows={rows}
          loading={isLoading}
          hasError={hasError}
          onClickRow={onClickRow}
          columns={[
            { title: 'Name', key: 'name' },
            {
              title: 'Account',
              key: 'accountId',
              render: (accountId: string) =>
                isLoadingAccounts ? (
                  <Skeleton className="h-4 w-full" />
                ) : (
                  accountsById?.[accountId]?.name
                ),
            },
            {
              title: 'Location',
              key: 'locationName',
              render: (locationName: string) => locationName,
            },
            {
              title: 'Valid from',
              key: 'validFrom',
              render: (validFrom: string) => formatDate(validFrom),
            },
            { title: 'Until', key: 'validTo', render: (validTo: string) => formatDate(validTo) },
            { title: 'Price bands', key: 'priceBands', render: getShortPriceBandList },
          ]}
        />
      </PageContent>
    </Page>
  );
}
