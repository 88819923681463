import { Page, PageContent } from '@/components-shad/layout';
import { Header } from '@/components-shad/header/Header';
import { DataTable } from '@/components-shad/data-table/DataTable';
import { DataTableSearchPanel } from '@/components-shad/data-table';
import { StatusBadge } from '@/components-shad/StatusBadge';
import { Badge } from '@/components-shad/ui/badge';
import { formatDateTime } from 'shared/helpers';
import { locationStatusType } from 'shared/status-maps';
import { locationStatusTitle } from 'shared/title-maps';
import { useLocationListPage, sortOptions } from './useLocationListPage';

export function LocationListShad() {
  const {
    models: { isLoading, hasError, rows, pagination, total, query, sort },
    operations: { search, navigate, changeSort, refresh, onClickRow },
  } = useLocationListPage();

  return (
    <Page>
      <Header title="Locations" />
      <PageContent variant="column">
        <DataTableSearchPanel
          loading={isLoading}
          searchPlaceholderText="Search..."
          defaultSearchValue={query}
          pagination={pagination}
          total={total}
          onSearch={search}
          onNavigate={navigate}
          onReload={refresh}
          sort={sort}
          sortOptions={sortOptions}
          onChangeSort={changeSort}
        />

        <DataTable
          rows={rows}
          columns={[
            { title: 'Name', key: 'name' },
            {
              title: 'Type',
              key: 'type',
              render: (type) => <Badge variant="secondary">{type}</Badge>,
            },
            { title: 'Account', key: 'accountName' },
            { title: 'Warehouse', key: 'warehouseLocationName' },
            {
              title: 'Status',
              key: 'status',
              render: (status) => (
                <StatusBadge
                  status={status}
                  type={locationStatusType}
                  title={locationStatusTitle}
                />
              ),
            },
            {
              title: 'Created',
              key: 'createdAt',
              render: (createdAt: string) => formatDateTime(createdAt),
            },
          ]}
          loading={isLoading}
          hasError={hasError}
          onClickRow={onClickRow}
          sort={sort}
          sortOptions={sortOptions}
          onChangeSort={changeSort}
        />
      </PageContent>
    </Page>
  );
}
